import React from "react"
import SmileConfirmation from "../components/Form/SmileConfirmation"
import Layout from "../helper/layout"

const SmileConfirmationPage = () => {
  return (
    <Layout>
      <SmileConfirmation />
    </Layout>
  )
}

export default SmileConfirmationPage
